@import "../variables";
@import "../template/mixin_templates";
@import "../../foundation/functions";

/* Général */
body {
  background-color: $body_background_color;
  color: $couleur_text;
}

.wrapper {
  background-color: $content_background_color;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: $titre_color;
}

a {
  color: $texte_lien;

  &:hover, &:focus {
    color: $texte_lien_hover;
  }
}

select {
  border: 1px solid $border-generales;
}

.note-produit, [itemprop="author"] {
  color: $texte_lien;
}

.bouton, .button, input[type="submit"],
body .template_fiche_produit_3 #fiche-produit .wrap-description .side-tabs a,
body .banner_diaporama .bx-wrapper .bx-next, body .banner_diaporama .bx-wrapper .bx-prev,
.accueil_diaporama_template .slide-text .content-slide-text > a.addcommentaires,
.bx-wrapper .bx-controls-direction > a, .accueil_boutique_template_1 .bx-wrapper .bx-controls-direction > a,
.produits a.zoom, .produits .addbasket .inside-addbasket,
#top_fleche a {
  background: $btn_background_color;
  color: $btn_texte_color;

  &:hover, &:focus, &.active {
    background: $btn_background_color_hover;
    color: $btn_texte_color_hover;
  }
}

.bx-wrapper .bx-controls {
  .bx-pager.bx-default-pager {
    .bx-pager-link {
      border-color: $btn_background_color;

      &:focus, &.active {
        background-color: $btn_background_color;
      }
    }
  }
}

/* site en deux colonnes */
.sidebar {
  border: 1px solid $colone_gauche_border_color;
  background-color: $colonne_gauche_background;
}

#nav-static-principal {
  .navigation_button_p {

  }
  .navigation_link_p {
    background: $colonne_gauche_link_background;
    color: $colonne_gauche_link_color;
  }

  .sous_navigation_link_p {
    border-bottom: $colone_gauche_border_color;
    color: $colonne_gauche_sous_link_color;

    &.actif, &:hover, &:focus {
      color: $colonne_gauche_sous_link_color_hover;
    }
  }
}

.title_section {
    color: $bloc-fil-title-color;
}

#bloc-fil-title {
  background-color: $bloc-fil-title-background;



  .arianne {
    li, a {
      color: $bloc-fil-title-color;
    }

    a:hover, a:focus {
      color: $bloc-fil-title-hover;
    }
  }
}

/* Header */

header #blocContent {
  background-color: $header_background;
}

.slogan {
  color: $slogan_color_texte;
}


.fixed {
  #nav-principal {
    border-bottom-color: $nav_border_color;
  }
}

/* Menu */
#nav-principal {
  background-color: $nav_background_color;
  //1er niveau menu
  .navigation_link_p {
    color: $nav_texte_color;

    &.actif,
    &:hover,
    &:focus {
      background-color: $li_background_color_hover;
      color: $nav_texte_color_hover;
    }
  }

  .menu_burger {
    background: transparent !important;
    color: transparent !important;
  }

  .navigation_button_p {
    background-color: $li_background_color;
    color: $nav_texte_color;

    .menu_burger_icons {
      background-color: #000;

      .menu__line {
        background-color: #fff;
      }
    }

    > ul {
      border-color: $nav_border_color;
      background-color: $sous_nav_background-color;
    }

  }

  //2-3ème niveau menu
  .sous_navigation_link_p {
    color: $nav_sous_texte_color;

    &.actif {
      color: $nav_sous_texte_color_hover;
    }
  }
}

/* Produits */

.produits {
  background-color: $fond_produits;

  .remise {
    background: $etiquette_produit_background;
    color: $etiquette_produit_texte;
  }

  .produits-bloc-image {
    border-color: $border-generales;
  }

  .nomprod, .desc, .prix {
    color: $produits_text_color;
  }

  a:hover, a:focus {
    color: $produits_text_color_hover;
  }
}

.template_boutique_3 .produits {
  border: 1px solid $border-generales;
}

.produit_etiquette, .produit_etiquette2, .produit_etiquette3 {
  background: $etiquette_produit_background;

  h3, .h3 {
    color: $etiquette_produit_texte;
  }
}

#fiche-produit {
  .notification_product, .stock {
    border-color: $border-generales;
  }

  .remise-produit {
    background-color: $etiquette_produit_background;
    color: $etiquette_produit_texte;
  }

  .wrap-images {
    .grande-image {
      border-color: $border-generales;
    }
  }

  .wrap-description {
    .declinaison-attribute-group, .product_options-group {
      .radio_unique, .radio_color {
        border-color: $border-generales;
      }
    }

    .prix {
      .prixprod {
        color: $texte_lien;
      }
    }

    .bloc-quantite {
      input, .bt-quantity {
        border-color: $border-generales;
      }
    }
  }

  #savoirplus, #produit-comment {
    border-color: $border-generales;

    > h4, > .h4 {
      border-color: $border-generales;
    }
  }

  #produit-comment {
    .message-wrap {
      border-color: $border-generales;
    }

    #addcomment > div {
      border-color: $border-generales !important;
    }

    .form-control {
      border-color: $border-generales;
    }

    [itemprop="description"] > p {
      border-color: $border-generales;
    }
  }
}

.template_fiche_produit_1 #fiche-produit {
  #sliderImagePreviews {
    li {
      border-color: $border-generales;
    }
  }
}

.template_fiche_produit_2 #fiche-produit {
  .sliderImageFicheProd, #sliderImagePreviews > a {
    border-color: $border-generales;
  }

  .wrap-description {
    border-color: $border-generales;
  }

  #savoirplus .details {
    border-color: $border-generales;
  }
}

.template_fiche_produit_3 #fiche-produit {
  .declinaisons-achat {
    border-color: $border-generales;
  }

  .details-descr-com {
    .side-tabs {
      &, & > a {
        border-color: $border-generales;
      }
    }

    .contents-tabs {
      border-color: $border-generales;
    }
  }

  .wrap-images {
    .grande-image {
      border-color: $border-generales;
    }
  }
}


/* Galerie photo */

.galerie {
  .addcommentaires {
    background: $etiquette_produit_background;
    color: $etiquette_produit_texte;
  }

  .commentP p {
    background: $etiquette_produit_background;
    color: $etiquette_produit_texte;
  }
}

/* Livre d'or */

.template_livreor {
  &.template_livreor_1 {
    .message_lo {
      border-color: $border-generales;

      .note {
        border-color: $message_lo_color;
      }

      .message {
        border-color: $message_lo_color;
      }
    }
  }

  &.template_livreor_2 {
    .message_lo {
      .IDLO {
        border-right-color: $border-generales;
        border-bottom-color: $border-generales;
        border-top-color: $border-generales;
        border-left-color: $message_lo_color;
      }

      .contenuMsgLO {
        .blcFleche::before {
          border-color: $border-generales;
        }

        border-color: $border-generales;

        &::before, &::after {
          background-color: $border-generales;
        }
      }

      &:hover {
        .IDLO {
          border-color: $message_lo_color;
        }

        .contenuMsgLO {
          .blcFleche::before {
            border-color: $message_lo_color;
          }

          border-color: $message_lo_color;

          &::before, &::after {
            background-color: $message_lo_color;
          }
        }
      }
    }
  }

  &.template_livreor_3 {
    .message_lo {
      border-color: $border-generales;

      .message {
        &::before, &::after {
          color: $message_lo_color;
        }
      }
    }
  }
}

/* Contact */

.template_contact {
  &.template_contact_2 {
    input[type="text"], select, textarea {
      &, &:hover, &:focus {
        border-color: $border-generales;
      }
    }
  }
}

body {
  .roundedgraymsg, input[type="text"], input[type="password"], select, textarea {
    border-color: $border-generales;
  }

  .formulaires_perso {
    textarea, select {
      border-color: $border-generales;
    }
  }
}

.groupe-radio,
.groupe-checkbox {
  label {
    &::before {
      background-color: #fff;
      color: $texte_lien;
      border-color: $message_lo_color;
    }

    &::after {
      background-color: $message_lo_color;
    }
  }
}

/* Blog / Boutons */

.template_blog {
  &.template_blog_1 {
    .apercu-article {
      background-color: $fond_produits;

      .titre-article-apercu, .description-courte-article-texte, .date-article, .short-article-tag-link-apercu {
        color: $produits_text_color;
      }
    }

    .blog-sidebar {
      border-color: $colone_gauche_border_color;
      background-color: $colonne_gauche_background;

      h4 {
        background: $colonne_gauche_link_background;
        color: $colonne_gauche_link_color;
      }

      a {
        border-bottom-color: $colone_gauche_border_color;
        color: $colonne_gauche_sous_link_color;

        &:hover, &:focus {
          color: $colonne_gauche_sous_link_color_hover;
        }
      }

      .blog-sidebar-bloc.blog-tags > ul > li:last-child > a {
        border-bottom-color: $colone_gauche_border_color;
      }
    }
  }
}

.pagination {
  > li > a {
    background-color: $btn_texte_color;
    color: $btn_background_color;
  }

  li.active a {
    background-color: $btn_background_color;
    border-color: $btn_background_color;
    color: $btn_texte_color;
  }
}

/* Mon compte */

#bloc-mon-compte {
  .sidebar nav a {
    border-color: $border-generales;
  }

  .bloc-mon-compte-content {
    border-color: $border-generales;
  }

  .infosclient_sous {
    border-color: $border-generales;

    h4, .h4, .dotted {
      border-color: $border-generales;
    }
  }
}

/* Footer */

footer {
  background-color: $footer_background_color;
  color: $footer_texte_color;
  border-color: $border-generales;

  a {
    color: $footer_texte_color;
  }
}

/* Template Panier */

.template-panier {
  background-color: $panier-background-color;
  color: $panier-titre-color;

  .connexion {

    p {
      border-color: $panier-titre-color;
      color: $panier-titre-color;
    }

    a {
      color: $panier-titre-color;
    }
  }


  #header-panier {
    color: $panier-titre-color;
  }

  #paniercontent {
    background-color: $panier-content-background-color;
    border-color: $panier-background-color;

    &, #paniertable {
      color: $panier-texte-color;
    }
  }

  &.template-panier-1 {
    #paniercontent {
      &, #paniertable {
        color: $panier-titre-color;
      }
    }
  }

  .count-live {
    background: $panier-content-background-color;
    color: $panier-texte-color;
  }

  &.template-panier-2 {
    #header-panier, #header-panier > .fa, #header-panier > .paniertoggle {
      color: $nav_texte_color_hover;
    }

    #header-panier {
      background-color: $li_background_color_hover;
    }
  }
}

/* Responsive */

@media #{$small-and-down} {
  .dropDown {
    color: inherit;
  }
}

.button.button-secondary {
  background: orange;

  &:hover {
    background: #444;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.step-done {
  background: $couleur_principale;
  border-color: $couleur_principale;

  a {
    color: #fff;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 .side-tabs ul li.active {
  border-color: $couleur_principale;
  color: $couleur_principale;

  a, span {
    color: $couleur_principale;
  }
}

#flux-panier.template_flux_panier.template_flux_panier_1 {
  color: #000;
}